<template>
    <div class="question question-single-choice">
        <div class="question-title" v-if="question.text">
            <trans :translationKey="question.text"></trans>
        </div>

        <div class="question-title"
             v-if="question.nonTranslatedText"
             v-html="question.nonTranslatedText"
        ></div>

        <div class="question-instructions" v-if="question.instructions">
            <trans :translationKey="question.instructions"></trans>
        </div>

        <div class="question-desc" v-if="question.desc">
            <trans :translationKey="question.desc"></trans>
        </div>

        <div class="answers" :style="answerWidth">
            <label
                v-for="(answerText, answerId) in question.answers"
                :key="question.id + '_' + answerId"
                class="form-radio answer"
                :class="{selected: (answerId == value) }"
            >
                <input type="radio"
                       :name="'answer' + question.id"
                       :value="answerId"
                       v-model="value"
                >
                <i class="form-icon"></i>
                <trans :translationKey="answerText"></trans>
            </label>
        </div>

        <div class="comment" v-if="showComment" :style="answerWidth">
            <div class="form-group">
                <label class="form-label"
                       :for="commentId">
                    <trans :translationKey="question.commentText"></trans>
                </label>
                <input class="form-input"
                       type="text"
                       v-model="commentValue"
                       :id="commentId"
                >
            </div>
        </div>
    </div>
</template>
<script>
import StoreContainer from '../../store';
import emitter from "../../services/Mitt";
import Trans from "../Trans";

export default {
    props: [
        'question'
    ],

    components: {
        Trans
    },

    data() {
        return {
            value: false,
            commentValue: '',

            store: StoreContainer.data
        }
    },

    mounted() {
        this.getValuesFromStore();
    },

    computed: {
        showComment() {
            return this.question.comment && this.question.comment == this.value
        },

        commentId() {
            if(!this.question.comment) {
                return false;
            }

            return this.question.id + '_' + this.question.comment + '_' + 'comment';
        },
        answerWidth() {
            if(typeof this.question.width === 'undefined' || !this.question.width) {
                return {};
            }

            return {
                width: this.question.width + 'px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },

    methods: {
        getValuesFromStore() {

            if(typeof this.store.answers[this.question.id] !== 'undefined') {
                this.value = this.store.answers[this.question.id];
            }

            if(typeof this.store.answers[this.commentId] !== 'undefined') {
                this.commentValue = this.store.answers[this.commentId];
            }
        },
    },

    watch: {
        value(newValue) {
            this.store.answers[this.question.id] = newValue;
        },

        commentValue(newValue) {
            this.store.answers[this.commentId] = newValue;
        }
    }
}
</script>
<style lang="scss">
.question {
    margin: 1.5em 0 3em 0;
    font-size: 1.5em;
}

.question-title {
    font-weight: bold;
    margin-bottom: 0.5em
}

.question-instructions {
    background-color: #fff0f0;
    border: #ffa0a0 1px solid;
    color: darkred;
    display: inline-block;
    padding: 0.2em 1em;
    margin: 0 2em;
}

.question-desc {
    font-style: italic;
    color: grey;
}

.answers {
    margin-top: 1em;
}

.answers select, .comment select {
    height: 2em;
    font-size: 1em;
    text-align: center;
}

.answer {
    display: block;
    padding: 0.5em 2em;
    text-align: left;
    border: 1px solid lightgrey;
    margin-bottom: 0.2em;
    cursor: pointer;
    line-height: 1.4em;
}

.comment input[type=text], .answers input[type=text], .answers textarea {
    height: 2em;
    font-size: 1em;
}

.answer.selected {
    background-color: lightblue;
}

.answers .form-checkbox .form-icon, .answers .form-radio .form-icon {
    left: 0.6em;
    top: 0.9em;
}

.comment {
    text-align: left;
    margin-top: 1em;
}

</style>