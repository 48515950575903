<template>
    <div class="home">

        <div v-if="store.currentId">
            <div class="padded-div" >
                <button
                    @click="continueWithSurvey()"
                    class="btn btn-error btn-lg">
                    pokračovat #{{ store.currentId }}
                </button>
            </div>
        </div>

        <div>
            <div class="padded-div">
                <button
                    @click="startNewSurvey()"
                    :class="{disabled: (!structureLoaded || !store.respondentId)}"
                    class=" btn btn-primary btn-success btn-lg">
                    Nový respondent
                </button>
            </div>
            <div class="padded-div">
                <button
                    @click="goToResponsesLog()"
                    class="btn btn-primary btn-lg"
                >Procházet starší záznamy
                </button>
            </div>
        </div>

        <div>
            <div class="padded-div">
                <button
                    @click="downloadSurveyStructure()"
                    class="btn btn-lg"
                    :class="{'btn-primary': structureLoaded, 'btn-error': !structureLoaded}"
                >Stáhnout dotazník
                </button>
            </div>
            <div class="padded-div">
                <button class="btn btn-lg"
                        :class="{'btn-primary': store.respondentId, 'btn-error': !store.respondentId}"
                        @click="$router.push('/staff')"
                >Nastavit ID tazatele
                </button>
            </div>
        </div>

        <div class="data-info">

            <h1 v-if="store.respondentId">Tazatel: {{ store.respondentId }}</h1>

            <div v-if="structureLoaded">
                Dotazník stažen &nbsp;&nbsp; {{ downloadedString }}
            </div>
            <div v-else>
                Není stažena struktura dotazníku
            </div>
            <div v-for="(lang, key) in translationStatus" :key="key">
                <div v-if="lang.version">
                    Překlad {{ key }} &nbsp;&nbsp; {{ formatDateTime(lang.downloadedAt) }}
                </div>
                <div v-else>
                    Není stažena struktura dotazníku
                </div>
            </div>
        </div>

        <div>
            verze 1.7
        </div>


    </div>
</template>

<script>
import Survey from '../services/Survey.js';
import StoreContainer from '../store.js';
import IdbService from "../services/IdbService";
import http from "../services/Http";
// import httpLocal from "../services/HttpLocal";
import httpLocal from "../services/Http";
import mitt from "../services/Mitt";
import Tranlsations from "../services/Translations";
import _ from "lodash";
import Store from "../store";
import {keys, getMany} from "idb-keyval";

export default {
    name: 'Home',

    data() {
        return {
            store: StoreContainer.data,
            structureLoaded: false,
            structureLoadedAt: false,
            translationStatus: {}
        }
    },

    mounted() {
        // set structure to indexedDB
        IdbService.getSurveyStructure()
            .finally(() => {
                this.store.applicationInitialized = true;

                this.displayStructureStatus();
            });

        mitt.on('displayStoreStatus', () => {
            this.displayStructureStatus();
        })
    },

    computed: {
        downloadedString() {
            if (!this.structureLoadedAt) {
                return '';
            }

            return this.formatDateTime(this.structureLoadedAt)
        }
    },

    methods: {
        formatDateTime(timestamp) {

            let date = new Date()

            date.setTime(timestamp);

            return date.toLocaleDateString(
                'cs-CZ',
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                }
            ) + ' ' + date.toLocaleTimeString(
                'cs-CZ'
            );
        },

        displayStructureStatus() {
            if (Survey.getVersion()) {
                this.structureLoaded = true;

                this.structureLoadedAt = Survey.getDownloadTime();
            }

            _.forEach(window.surveyConfig.languages, (language) => {
                if (Tranlsations.getVersion(language)) {
                    this.translationStatus[language] = {
                        'downloadedAt': Tranlsations.getDownloadTime(language),

                        'version': Tranlsations.getVersion(language),
                    }
                }
            })
        },

        downloadSurveyStructure() {
            httpLocal.get('/survey').then((response) => {
                IdbService.setSurveyStructure(
                    response.data
                ).then(() => {
                    IdbService.getSurveyStructure()
                        .finally(() => {
                            this.store.applicationInitialized = true;

                            mitt.emit('displayStoreStatus');
                        });
                })
            })

            Tranlsations.downloadTranslations();
        },

        startNewSurvey() {
            IdbService.incrementMaxId()
                .finally(() => {

                    IdbService.getMaxId().then((val) => {
                        Store.data.currentId = val;

                        IdbService.setCurrentId(val)
                            .finally()
                    })


                    const groups = ['KONTROL', 'ENVI', 'BALENA'];
                    const cena = [20, 30, 40, 50, 60];
                    const prct = [10, 20, 30, 50, 75];
                    const food = ['BIO', 'LOCAL']
                    const guide = [1, 0]
                    const kraceni = [1, 0]

                    this.store.answers = {
                        GROUP: _.sample(groups),
                        CENA: _.sample(cena),
                        PRCT: _.sample(prct),
                        FOOD: _.sample(food),
                        GUIDE: _.sample(guide),
                        KRACENI: _.sample(kraceni),
                        VERSION: 1.7
                    };

                    this.$router.push({
                        name: 'Page',
                        params: {
                            pageNumber: window.surveyConfig.start
                        }
                    });
                })
        },

        continueWithSurvey() {
            this.$router.push({
                name: 'Page',
                params: {
                    pageNumber: window.surveyConfig.start
                }
            });
        },

        goToResponsesLog() {
            this.$router.push({
                name: 'Log'
            });
        }

    }
}
</script>

<style lang="scss">
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
}

.home > div {
    display: flex;

}

.home .padded-div {
    padding: 1em;

}

.home .btn-lg {
    min-width: 400px;
    height: 4em;
    font-size: 1.4em;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.home .data-info {
    display: block;
    margin-top: 2em;
    font-size: 0.9em;
    color: gray;
}

.home .btn {
    text-transform: uppercase;
}
</style>
