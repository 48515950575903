import { set, get, update } from 'idb-keyval';
import Survey from "./Survey";
import Translations from "./Translations";
import Store from "../store";

export default {
    setSurveyStructure(structure) {

        let data = {
            structure: structure,
            downloadedAt: Date.now(),
            version: 1
        };

        return set('surveyStructure', data)
    },

    getSurveyStructure() {
        return get('surveyStructure')
            .then((val) => {
                Survey.setStructure(val);
            }).catch(() => {
                console.log('No data found');
            })
    },

    setTranslations(language, strings) {

        let data = {
            strings: strings,
            downloadedAt: Date.now(),
            version: 1
        };

        return set('translations_' + language, data)
    },

    getTranslations(language) {
        return get('translations_' + language)
            .then((val) => {
                Translations.setStrings(language, val);
            }).catch(() => {
                console.log('No data found ' + language);
            })
    },

    setTranslationsEnabled(value) {
        return set('translationsEnabled', value)
    },

    getTranslationsEnabled() {
        return get('translationsEnabled')
            .then((val) => {
                Store.data.translationsEnabled = false;

                if(val) {
                    Store.data.translationsEnabled = true;
                }
            })
    },

    setLang(value) {
        return set('lang', value)

    },

    getLang() {
        return get('lang')
            .then((val) => {
                Store.data.currentLanguage = 'cz';

                if(val) {
                    Store.data.currentLanguage = val;
                }
            })
    },

    getMaxId() {
        return get('maxId')
    },

    incrementMaxId() {
        return update('maxId', (val) => (val || 0) + 1)
    },

    setCurrentId(value) {
        return set('currentId', value)
    },

    getCurrentId() {
        return get('currentId')
            .then((val) => {
                Store.data.currentId = false;

                if(val) {
                    Store.data.currentId = val;
                }
            })
    },

    saveAnswers(answers, currentId) {

        let key =  'x_resp_' + currentId;

        return get(key)
            .then((data) => {

                let output ;

                if(data) {
                    output = data;

                    output['answers'] = Store.data.answers
                } else {

                    Store.data.started = Date.now();

                    output = {
                        answers: Store.data.answers,
                        started: Date.now(),
                        uploaded: false,
                        respondentId: Store.data.respondentId,
                        id: currentId
                    }

                }



                set(key, output);
            })
    },
    getAnswers(currentId) {
        return get('x_resp_' + currentId)
            .then((val) => {
                Store.data.answers = {};

                if(val) {
                    Store.data.answers = val.answers;
                    Store.data.started = val.started
                }
            })
    },
    markUploaded(id) {
        let key =  'x_resp_' + id;

        return get(key)
            .then((data) => {

                let output ;

                if(data) {
                    output = data;

                    output['uploaded'] = true
                }

                set(key, output);
            })
    },
    markUploaded2(id) {
        let key =  'x_resp_' + id;

        return get(key)
            .then((data) => {

                let output ;

                if(data) {
                    output = data;

                    output['uploaded2'] = true
                }

                set(key, output);
            })
    },
    markFinished(id) {
        let key =  'x_resp_' + id;

        return get(key)
            .then((data) => {

                let output ;

                if(data) {
                    output = data;

                    output.finished = Date.now()
                }

                set(key, output).finally(() => {
                });
            })
    },
    setRespondentId(value) {
        return set('respondentId', value)
    },

    getRespondentId(value) {
        return get('respondentId')
            .then((val) => {
                Store.data.respondentId = '';

                if(val) {
                    Store.data.respondentId = val;
                }
            })
    }
}