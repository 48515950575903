import StoreContainer from "../store";
import __ from "../services/__";
import IdbService from "../services/IdbService";

function getTimeTest() {
    let ARRIVAL = StoreContainer.getAnswer('ARRIVAL');
    let START = StoreContainer.data.started;

    let arrivalDate = new Date(ARRIVAL);

    let now = new Date(START);

    let date8th = new Date('2021-08-08T00:01');

    // To calculate the no. of days between two dates
    let DifferenceInHours = (now.getTime() - arrivalDate.getTime()) / (1000 * 3600 );

    if(arrivalDate.getTime() > date8th.getTime()) {
        return 'MINULOST';
    }

    if(DifferenceInHours > 3) {
        return 'SOUCASNOST';
    }

    return 'BUDOUCNOST';
}

const proParseHooks = {
    page103: (page) => {
        let timeTest  = getTimeTest()

        StoreContainer.data.answers['TIMETEST'] = timeTest;

        if(!(timeTest === 'SOUCASNOST' || timeTest === 'MINULOST')) {
            page.questions = [];
        }

        return page;
    },

    page104: (page) => {
        let timeTest  = getTimeTest()

        StoreContainer.data.answers['TIMETEST'] = timeTest;

        if(!(timeTest === 'SOUCASNOST' || timeTest === 'BUDOUCNOST')) {
            delete page.questions.Q3.subQuestions['b'];
        }

        return page;
    },

    page108: (page) => {
        let timeTest  = getTimeTest()

        StoreContainer.data.answers['TIMETEST'] = timeTest;

        if(!(timeTest === 'SOUCASNOST' || timeTest === 'BUDOUCNOST')) {
            delete page.questions.Q8.subQuestions['b'];
        }

        return page;
    },

    page200: (page) => {

        // introtext
        let lang = StoreContainer.data.currentLanguage;

        let GROUP = StoreContainer.getAnswer('GROUP');

        page.nonTranslatedText = __(page.text, lang) + __(page['text' + GROUP], lang)

        page.text = '';

        // adding price to question
        let cena = StoreContainer.getAnswer('CENA');

        page.questions.Q14.nonTranslatedText = __(page.questions.Q14.text, lang);

        page.questions.Q14.nonTranslatedText = page.questions.Q14.nonTranslatedText.replace('[CENA]', cena)

        page.questions.Q14.text = '';

        return page;
    },

    page201: (page) => {

        let lang = StoreContainer.data.currentLanguage;

        // adding price to question
        let prct = StoreContainer.getAnswer('PRCT');

        let food = StoreContainer.getAnswer('FOOD');

        page.questions.Q16.nonTranslatedText = __(page.questions.Q16['text' + food], lang);

        console.log(page.questions.Q16.nonTranslatedText)

        page.questions.Q16.nonTranslatedText = page.questions.Q16.nonTranslatedText.replace('[PRCT]', prct)

        return page;
    },

    page407: (page) => {

        let lang = StoreContainer.data.currentLanguage;

        let guide = StoreContainer.getAnswer('GUIDE', 'int');
        let Q18 = StoreContainer.getAnswer('Q18', 'int');

        page.nonTranslatedText = __('p407.base', lang)

        if(guide === 1 && (Q18 === 2 || Q18 === 88)) {
            page.nonTranslatedText += __('p407.guide', lang)
        }

        return page;
    },

    page500: (page) => {

        let lang = StoreContainer.data.currentLanguage;

        let guide = StoreContainer.getAnswer('GUIDE', 'int');
        let Q18 = StoreContainer.getAnswer('Q18', 'int');

        if(guide !== 1 || Q18 !== 2) {
            delete page.questions['DB4'];
        }

        return page;
    },

    page501: (page) => {
        IdbService.markFinished(StoreContainer.data.currentId);

        return page;
    },


}

export default proParseHooks;
