<template>
    <div class="page">
        <div class="page-content">

            <div class="page-instructions"
                 v-if="currentPage.instructions"
            >
                <trans :translationKey="currentPage.instructions"></trans>
            </div>

            <div class="page-text"
                 v-if="currentPage.text"
            >
                <trans :translationKey="currentPage.text"></trans>
            </div>

            <div class="page-text"
                 v-if="currentPage.nonTranslatedText"
                 v-html="currentPage.nonTranslatedText"
            ></div>

            <div v-if="questions">
                <div
                    v-for="(question, questionId) in questions"
                    :key="questionId"
                >
                    <component
                        :is="question.type"
                        :question="question"
                        v-show="displayQuestion(question)"
                        :key="'q' + question.id"
                    ></component>
                </div>
            </div>
            <div v-if="!Object.keys(questions).length && !isEnd">
                <h1 style="margin-top: 2em;">... continue please ...</h1>
            </div>

            <div v-if="!Object.keys(questions).length && isEnd">
                <h1 style="margin-top: 2em;" class="page-instructions">Hotovo. Tazateli, moc děkujeme za skvělou
                    práci!</h1>
            </div>

        </div>

        <div class="page-navigation">
            <div class="flex-1 text-left">

                <span style="margin-left: 1em;">
                    <a class="btn btn-primary" @click="setIncomplete()">ukončit</a>
                </span>

                <span style="margin-left: 1em;">
                    Strana: {{ currentPage.id }}
                </span>

                <span style="margin-left: 10em;">
                    <a class="btn btn-primary"
                       :class="{'btn-success': store.translationsEnabled, 'btn-primary': !store.translationsEnable}"
                       @click="toggleTrans()"
                    >t</a>
                </span>

                <span style="margin-left: 3em;">
                    <a class="btn"
                       :class="{'btn-success': (store.currentLanguage === 'cz'), 'btn-primary': (store.currentLanguage === 'en')}"
                       @click="setLanguage('cz')"
                    >cz</a>
                </span>
                <span style="margin-left: 1em;">
                    <a class="btn "
                       :class="{'btn-success': (store.currentLanguage === 'en'), 'btn-primary': (store.currentLanguage === 'cz')}"
                       @click="setLanguage('en')"
                    >en</a>
                </span>


            </div>
            <div>
                <button
                    @click="goToNextPage()"
                    class="btn btn-primary btn-lg navigation"
                >pokračovat
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Survey from '../services/Survey.js';
import StoreContainer from '../store.js';
import IdbService from "../services/IdbService";
import _ from "lodash";

import SingleChoice from "../components/questions/SingleChoice";
import DateTime from "../components/questions/DateTime";
import SelectNumbers from "../components/questions/SelectNumbers";
import Country from "../components/questions/Country";
import MultipleSliders from "../components/questions/MultipleSliders";
import GridWithSideValues from "../components/questions/GridWithSideValues";
import MultipleChoice from "../components/questions/MultipleChoice";
import Text from "../components/questions/Text";
import TextArea from "../components/questions/TextArea";
import SelectTexts from "../components/questions/SelectTexts";

import Trans from "../components/Trans";
import mitt from "../services/Mitt";
import postProcessHooks from '../hooks/PostProcessHoos.js';
import preParseHooks from "../hooks/PreParseHooks";


export default {
    name: "Page",

    components: {
        SingleChoice,
        DateTime,
        Trans,
        SelectNumbers,
        Country,
        MultipleSliders,
        GridWithSideValues,
        MultipleChoice,
        Text,
        TextArea,
        SelectTexts
    },

    data() {
        return {
            store: StoreContainer.data,
            currentPage: {}
        }
    },

    mounted() {
        if (this.store.applicationInitialized) {
            this.currentPage = Survey.getPage(this.$route.params.pageNumber);
        } else {
            // set structure to indexedDB
            IdbService.getSurveyStructure()
                .finally(() => {
                    this.store.applicationInitialized = true;

                    this.loadPage(this.$route.params.pageNumber);
                });
        }
    },

    computed: {
        questions() {
            if (
                typeof this.currentPage === 'undefined' ||
                typeof this.currentPage.questions === 'undefined'
            ) {
                return false;
            }

            return this.currentPage.questions;
        },

        isEnd() {
            return window.surveyConfig.end === this.currentPage.id
        }
    },

    methods: {
        goToNextPage() {

            let next = this.currentPage.next;

            if (typeof postProcessHooks['page' + this.currentPage.id] !== 'undefined') {
                next = postProcessHooks['page' + this.currentPage.id](this.currentPage);
            }

            IdbService.saveAnswers(this.store.answers, this.store.currentId)
                .finally(() => {
                    if (next === 'end') {
                        this.$router.push({
                            name: 'Home'
                        });
                    } else {
                        this.$router.push({
                            name: 'Page',
                            params: {
                                pageNumber: next
                            }
                        });
                    }


                })
        },

        setIncomplete() {
            this.store.answers.INCOMPLETE = 1;

            IdbService.saveAnswers(this.store.answers, this.store.currentId)
                .finally(() => {
                    this.$router.push({
                        name: 'Page',
                        params: {
                            pageNumber: 500
                        }
                    });
                })
        },

        loadPage(pageNumber) {
            let page = _.cloneDeep(Survey.getPage(pageNumber));

            if (typeof preParseHooks['page' + page.id] !== 'undefined') {
                page = preParseHooks['page' + page.id](page);
            }

            this.currentPage = page;
        },

        displayQuestion(question) {
            if (typeof question.filters === "undefined") {
                return true;
            }

            let showQuestion = false;

            let dependsOn;
            let comparison;
            let dependsOnValue;

            _.forEach(question.filters, (filter) => {

                dependsOn = filter[0];
                comparison = filter[1];
                dependsOnValue = filter[2];

                if (comparison === 'EQ') {
                    if (
                        this.store.answers[dependsOn] !== 'undefined'
                        && String(this.store.answers[dependsOn]) === String(dependsOnValue)
                    ) {
                        showQuestion = true;

                        return false;
                    }
                }

                if (comparison === 'GT') {
                    if (
                        this.store.answers[dependsOn] !== 'undefined'
                        && parseInt(this.store.answers[dependsOn]) > parseInt(dependsOnValue)
                    ) {
                        showQuestion = true;

                        return false;
                    }
                }

                if (comparison === 'IN') {
                    console.log(this.store.answers[dependsOn])

                    if (
                        this.store.answers[dependsOn] !== 'undefined'
                        && dependsOnValue.includes(String(this.store.answers[dependsOn]))
                    ) {
                        showQuestion = true;

                        return false;
                    }
                }
            })

            return showQuestion;
        },

        toggleTrans() {
            this.store.translationsEnabled = !this.store.translationsEnabled;

            IdbService.setTranslationsEnabled(this.store.translationsEnabled)
        },

        setLanguage(lang) {
            this.store.currentLanguage = lang;

            IdbService.setLang(lang)
        }
    },

    watch: {
        $route(to, from) {
            if(to.name === 'Page') {
                this.loadPage(to.params.pageNumber);
            }
        }
    }
}
</script>

<style>
.page {
    display: flex;
    flex-direction: column;

    justify-content: center;
    min-height: 100vh;
    width: 100vw;
}

.page-content {
    flex: 1;
}

.page-navigation {
    display: flex;
    width: 100%;
    background-color: lightgrey;
    align-items: center;
}

.page-navigation .btn {
    text-transform: uppercase;
}

.page-navigation .btn.navigation {
    height: 3em;
    font-size: 1.5em;
}

.page-instructions, .page-text {
    font-size: 1.5em;
    margin: 0.5em auto;
    padding: 0.2em 2em;
    max-width: 1000px;
}

.page-instructions p:last-child, .page-text p:last-child {
    margin-bottom: 0;
}

.page-instructions {
    color: darkred;
    background-color: #fff0f0;
    border: #ffa0a0 1px solid;
}

</style>